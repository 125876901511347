body{
  background-color: #eee;
  color: #555;
  align-items: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 90%;
  min-width: 80%;
  margin: 2%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: #555;
  display: 'inline-block';
  border-radius: 10px !important;
  border-style: solid;
  /* mx: '2px',  */
  /* width: 65%;
  height: 65%; */
  text-Align: left;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
